<template>
  <div class="row">
    <div class="col-12">
      <Navbar v-bind:title="'FunBook'" v-bind:isChevron="false"></Navbar>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-start">
      <Card v-if="unitId === 1" v-bind:badge-text="'7 lat'"
            v-bind:title="'Klasa 1'"
            v-bind:progress="unitsProgress ? (unitsProgress[0] ? unitsProgress[0].progress : 0) : 0"
            v-bind:background-image="'url(../../../images/funbook/class-1.jpg) 0 -9rem;'"
            v-bind:unit-id="1">
      </Card>
      <Card v-if="unitId === 2" v-bind:badge-text="'8 lat'"
            v-bind:title="'Klasa 2'"
            v-bind:progress="unitsProgress ? (unitsProgress[1] ? unitsProgress[1].progress : 0) : 0"
            v-bind:background-image="'url(../../../images/funbook/class-2.jpg) 0 16rem;'"
            v-bind:unit-id="2">
      </Card>
      <Card v-if="unitId === 3" v-bind:badge-text="'9 lat'"
            v-bind:title="'Klasa 3'"
            v-bind:progress="unitsProgress ? (unitsProgress[2] ? unitsProgress[2].progress : 0) : 0"
            v-bind:background-image="'url(../../../images/funbook/class-3.jpg) 0 13rem;'"
            v-bind:unit-id="3">
      </Card>
      <Card v-if="unitId === 4" v-bind:badge-text="'10 lat'"
            v-bind:title="'Klasa 4'"
            v-bind:progress="unitsProgress ? (unitsProgress[3] ? unitsProgress[3].progress : 0) : 0"
            v-bind:background-image="'url(../../../images/funbook/class-4.jpg) 0 24rem;'"
            v-bind:unit-id="4">
      </Card>
      <Card v-if="unitId === 5" v-bind:badge-text="'11 lat'"
            v-bind:title="'Klasa 5'"
            v-bind:progress="unitsProgress ? (unitsProgress[4] ? unitsProgress[4].progress : 0) : 0"
            v-bind:background-image="'url(../../../images/funbook/class-5.jpg) 0 13rem;'"
            v-bind:unit-id="5">
      </Card>
      <Card v-if="unitId === 6" v-bind:badge-text="'12 lat'"
            v-bind:title="'Klasa 6'"
            v-bind:progress="unitsProgress ? (unitsProgress[5] ? unitsProgress[5].progress : 0) : 0"
            v-bind:background-image="'url(../../../images/funbook/class-6.jpg) 0 12rem;'"
            v-bind:unit-id="6">
      </Card>
      <Card v-if="unitId === 7" v-bind:badge-text="'13 lat'"
            v-bind:title="'Klasa 7'"
            v-bind:progress="unitsProgress ? (unitsProgress[6] ? unitsProgress[6].progress : 0) : 0"
            v-bind:background-image="'url(../../../images/funbook/class-7.jpg) 0 25rem;'"
            v-bind:unit-id="7">
      </Card>
      <Card v-if="unitId === 8" v-bind:badge-text="'14 lat'"
            v-bind:title="'Klasa 8'"
            v-bind:progress="unitsProgress ? (unitsProgress[7] ? unitsProgress[7].progress : 0) : 0"
            v-bind:background-image="'url(../../../images/funbook/class-8.jpg) 0 19rem;'"
            v-bind:unit-id="8">
      </Card>
    </div>
  </div>
  <div class="row text-start fm-lesson-label">
    <div class="col-12">
      <span class="fm-label-main-color fm-lesson-label">Lekcje</span>
    </div>
  </div>
  <ul class="list-group text-start fm-lessons-list fm-mobile-lessons-list">
    <template v-for="(category, index) in categories" v-bind:key="category.id">
      <li class="list-group-item fm-lessons-list-item" v-on:click="redirectToComponentsList(category.id)">
        <div class="row">
          <div class="col-10">{{ index + 1 }}. {{ category.name }}</div>
          <div class="col-2">
            <template v-if="subunitsProgress
                      && subunitsProgress.find(o => o.id === category.id)
                      && subunitsProgress.find(o => o.id === category.id).progress >= 50
                      && subunitsProgress.find(o => o.id === category.id).progress < 90">
              <img class="fm-funbook-icon" src="../../../public/images/icons/slightly-smiling-face.svg">
            </template>
            <template v-else-if="subunitsProgress
                      && subunitsProgress.find(o => o.id === category.id)
                      && subunitsProgress.find(o => o.id === category.id).progress > 90">
              <img class="fm-funbook-icon" src="../../../public/images/icons/smiling-face-with-sunglasses.svg">
            </template>
            <template v-else>
              <img class="fm-funbook-icon" src="../../../public/images/icons/sad-but-relieved-face.svg">
            </template>
          </div>
        </div>
      </li>
    </template>
  </ul>

</template>

<script>
import CategoryService from '../../services/categoryService';
import ComponentService from '../../services/componentService';
import Card from '../../components/FunBook/Card';
import Navbar from '../../components/Navbar';

export default {
  name: 'LessonList',
  components: { Navbar, Card },
  data() {
    return {
      subunitsProgress: null,
      categories: [],
      unitId: null,
      unitsProgress: []
    };
  },
  mounted() {
    this.initView();
  },
  methods: {
    async initView() {
      const routeParams = this.$route.params;
      this.unitId = routeParams.id ? parseInt(routeParams.id) : null;
      await this.getSubunits(this.unitId);
    },
    async getSubunits(unitId) {
      const response = await CategoryService.getUnits(1, 1, false, unitId);
      this.categories = response.data.categories.items;
      console.log(this.categories);

      if (this.categories === null) {
        const message = response.data.categories.message;

        if (message === 'User does not have active subscription') {
          this.inactiveAccessMessage = 'Użytkownik nie posiada wykupionego dostępu.';
        }
      }

      await this.getSubunitsProgress(unitId);
      await this.getUnitsProgress();
    },
    async redirectToComponentsList(unitId) {
      await this.$router.push({ name: 'Lesson', params: { id: unitId } });
    },
    async getSubunitsProgress(unitId) {
      const response = await ComponentService.getSubunitProgress(unitId);
      this.subunitsProgress = response.data;
    },
    async getUnitsProgress() {
      const response = await ComponentService.getUnitsProgress();
      this.unitsProgress = response.data;
    }
  }
};
</script>

<style scoped>
.fm-lesson-label {
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 0.5rem;
  padding-top: 1rem;
  margin-bottom: 1rem;
}

.fm-mobile-lessons-list {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  font-size: 1.125rem;
}
</style>
